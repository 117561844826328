// const SlugBh = {
//   led: "lam-bien-quang-cao-den-led-tai-bmt-daklak",
//   alu: "lam-bien-quang-cao-alu-tai-bmt-daklak",
//   mica: "lam-bien-quang-cao-mica-tai-bmt-daklak",
//   bat: "lam-bien-quang-cao-bat-hiflex-tai-bmt-daklak",
//   neon: "lam-bien-quang-cao-neon-tai-bmt-daklak",
//   inox: "lam-bien-quang-cao-inox-tai-bmt-daklak",
//   go: "lam-bien-quang-cao-go-tai-bmt-daklak",
// };
// export default SlugBh;

const data_bh_detail = [
  {
    id: 1,
    tieude: "Bảng hiệu đèn LED",
    timkiem: "lam-bien-quang-cao-den-led-tai-bmt-daklak",
    title_seo:
      "Làm biển quảng cáo đèn LED tại BMT, Đắk Lắk | Giá rẻ, đẹp, chất lượng 100%",
    des_seo:
      "Thiết kế & thi công biển quảng cáo tại BMT, Đắk Lắk và toàn quốc với giá rẻ nhất. Hơn 20 năm kinh nghiệm, phục vụ 1000+ khách hàng. Liên hệ: 096.4040.895",
    mota_sp: "Công nghệ LED hiệu suất cao, tiết kiệm năng lượng, độ bền cao..",
    hinhanh_sp: "/images/bh_denled-lam-bien-quang-cao-bmt-daklak.jpg",
    mota: [
      "Bảng hiệu đèn LED hay biển quảng cáo đèn led là một loại biển quảng cáo ngoài trời được sử dụng chủ yếu vào ban đêm.",
      "Các bóng đèn LED được thiết kế với nhiều màu sắc rực rỡ, hiển thị chi tiết nội dung quảng cáo để thu hút sự chú ý. Bảng hiệu LED rất phổ biến trong việc tạo điểm nhấn thương hiệu và giúp người đi đường dễ dàng ghi nhớ thương hiệu.",
      "Chúng hoạt động bằng cách kết nối với nguồn điện, sau đó các bóng LED sẽ phát sáng, “nhấp nháy” liên tục và hiển thị nội dung theo thiết kế ban đầu. Hiệu ứng LED có thể thay đổi, thông tin có thể biến hóa liên tục, và nội dung có thể “chạy” theo lập trình sẵn.",
      "Do đó, thông tin được thể hiện trên bảng LED chạy chữ rất đa dạng và phong phú, không bị giới hạn như việc in cắt, khắc, hay đắp dán chữ trực tiếp lên bảng hiệu.",
      "Rất nhiều khách hàng sẽ tỏ ra ái ngại về vấn đề tiêu tốn nhiều điện năng. Tuy nhiên, qua khảo sát thì mức tiêu hao điện năng của bóng đèn LED là cực nhỏ.  Độ bền cao, 1 biển quảng cáo led nếu như không bị va đập hay gặp gió bão làm hỏng tuổi thọ của chúng có thể lên đến 5 năm.",
    ],
    hinhanh: [
      {
        id: 1,
        link: "/images/banghieu/led1-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bien-quang-cao-den-led-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu đèn Led tròn tiệm spa",
      },
      {
        id: 2,
        link: "/images/banghieu/led2-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bang-hieu-den-led-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu quán nhậu đèn led",
      },
      {
        id: 3,
        link: "/images/banghieu/led3-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bien-quang-cao-den-led-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu đèn Led vào ban ngày",
      },
      {
        id: 4,
        link: "/images/banghieu/led4-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "Thiet-ke-bang-hieu-den-led-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu đèn Led chữ nổi",
      },
      {
        id: 5,
        link: "/images/banghieu/led5-lam-bien-quang-cao-bmt-daklak.png",
        alt: "thi-cong-bang-hieu-den-led-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu đèn Led chạy chữ, ma trận",
      },
      {
        id: 6,
        link: "/images/banghieu/led6-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thiet-ke-bang-hieu-den-led-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Led huỳnh quang",
      },
    ],
  },
  {
    id: 2,
    tieude: "Bảng hiệu Alu",
    timkiem: "lam-bien-quang-cao-alu-tai-bmt-daklak",
    title_seo:
      "Làm biển quảng cáo Alu tại BMT, Đắk Lắk | Giá rẻ, đẹp, chất lượng 100%",
    des_seo:
      "Thiết kế & thi công biển quảng cáo tại BMT, Đắk Lắk và toàn quốc với giá rẻ nhất. Hơn 20 năm kinh nghiệm, phục vụ 1000+ khách hàng. Liên hệ: 096.4040.895",
    mota_sp:
      "Chất liệu Alu bền bỉ, nhẹ, chống ăn mòn, mang lại vẻ đẹp hiện đại và tinh tế..",
    hinhanh_sp: "/images/banghieu/alu5-lam-bien-quang-cao-bmt-daklak.jpg",
    mota: [
      "Làm bảng hiệu Alu hiện đang là một một trong những xu hướng cực hot trong ngành bảng hiệu quảng cáo chuyên nghiệp không chỉ tại Việt Nam mà còn trên toàn thế giới cũng thường hay sử dụng loại bảng hiệu này.",
      "Bảng hiệu Alu làm từ chất liệu Aluminum, đây chính là tấm nhôm nhựa chuyên làm bảng hiệu quảng cáo. Là một kim loại chúng có khả năng chịu nhiệt cực tốt, bền bỉ trên nhiều môi trường khắc nghiệt. Những tấm nhôm Alu được liên kết với nhau rất chắc chắn có thể sử dụng lên đến 7 năm và màu sắc trên 5 năm.",
      "Biển quảng cáo mặt Alu được kết hợp với chữ nổi bằng Alu, mica, inox hoặc đồng. Chất liệu Alu sẽ được gắn thêm đèn LED hắt sáng hoặc đèn LED âm bên trong chữ nổi, được lập trình tạo nhấp nháy đa dạng và độc đáo.",
    ],
    hinhanh: [
      {
        id: 1,
        link: "/images/banghieu/alu1-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bien-quang-cao-alu-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Alu chữ nổi Alu",
      },
      {
        id: 2,
        link: "/images/banghieu/alu2-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bang-hieu-alu-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Alu chữ nổi Mica",
      },
      {
        id: 3,
        link: "/images/banghieu/alu3-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bien-quang-cao-alu-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Alu chữ nổi Inox",
      },
      {
        id: 4,
        link: "/images/banghieu/alu4-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thiet-ke-bang-hieu-alu-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Alu chữ nổi Alu, đèn Led",
      },
      {
        id: 5,
        link: "/images/banghieu/alu5-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bang-hieu-alu-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Alu chữ nổi, đèn pha Led chiếu ngoài",
      },
      {
        id: 6,
        link: "/images/banghieu/alu6-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bang-hieu-alu-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Alu, mặt dựng Alu",
      },
    ],
  },
  {
    id: 3,
    tieude: "Bảng hiệu Mica",
    timkiem: "lam-bien-quang-cao-mica-tai-bmt-daklak",
    title_seo:
      "Làm biển quảng cáo Mica tại BMT, Đắk Lắk | Giá rẻ, đẹp, chất lượng 100%",
    des_seo:
      "Thiết kế & thi công biển quảng cáo tại BMT, Đắk Lắk và toàn quốc với giá rẻ nhất. Hơn 20 năm kinh nghiệm, phục vụ 1000+ khách hàng. Liên hệ: 096.4040.895",
    mota_sp:
      "Chất liệu mica trong suốt, bền đẹp, dễ tạo hình, mang lại sự tinh tế và thu hút..",
    hinhanh_sp: "/images/banghieu/mica1-lam-bien-quang-cao-bmt-daklak.jpg",
    mota: [
      "Bảng Hiệu Mica với giá thành rẻ, đẹp mắt, đa dạng mẫu mã đã tạo được nhiều ấn tượng cho khách hàng nên hiện nay loại bảng hiệu này cũng được nhiều doanh nghiệp tin dùng.",
      "Bảng hiệu Mica với chất liệu chính là nhựa trong nên chúng khá dễ dàng kết hợp với các chất liệu khác khi làm bảng hiệu quảng cáo như: Alu, decal, inox, gỗ,… Hoặc cũng có thể sử dụng thêm đèn LED, đèn neon để tạo thêm nhiều hiệu ứng nổi bật khác cho bảng hiệu.",
      "Cũng chính vì điểm này mà ngày càng nhiều khách hàng ưu tiên lựa chọn sử dụng chất liệu mica để thiết kế bảng hiệu độc đáo, và đẹp mắt. Giá thành rẻ, bền cũng là trong những ưu điểm nổi bật giúp thu hút được người dùng sử dụng làm bảng hiệu mica.",
    ],
    hinhanh: [
      {
        id: 1,
        link: "/images/banghieu/mica1-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bien-quang-cao-mica-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Mica chữ nổi",
      },
      {
        id: 2,
        link: "/images/banghieu/mica2-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bang-hieu-mica-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Mica chữ nổi đèn Led",
      },
      {
        id: 3,
        link: "/images/banghieu/mica3-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bien-quang-cao-mica-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Mica dán decal",
      },
      {
        id: 4,
        link: "/images/banghieu/mica4-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thiet-ke-bien-quang-cao-mica-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu alu kết hợp chữ nổi Mica",
      },
      {
        id: 5,
        link: "/images/banghieu/mica5-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thiet-ke-bien-quang-cao-mica-tot-nhat-bmt-daklak",
        mota: "Biển logo chữ nổi bằng Mica",
      },
      {
        id: 6,
        link: "/images/banghieu/mica6-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thiet-ke-bien-quang-cao-mica-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Mica",
      },
    ],
  },
  {
    id: 4,
    tieude: "Bảng hiệu Bạt Hiflex",
    timkiem: "lam-bien-quang-cao-bat-hiflex-tai-bmt-daklak",
    title_seo:
      "Làm biển quảng cáo Bạt Hiflex tại BMT, Đắk Lắk | Giá rẻ, đẹp, chất lượng 100%",
    des_seo:
      "Thiết kế & thi công biển quảng cáo tại BMT, Đắk Lắk và toàn quốc với giá rẻ nhất. Hơn 20 năm kinh nghiệm, phục vụ 1000+ khách hàng. Liên hệ: 096.4040.895",
    mota_sp:
      "Chất liệu từ bạt hiflex có độ bền cao và khả năng chống thấm nước..",
    hinhanh_sp: "/images/banghieu/bat3-lam-bien-quang-cao-bmt-daklak.jpg",
    mota: [
      "Bảng hiệu Bạt Hiflex rất thông dụng, phổ biến nhất Việt Nam. Hơn hết là có giá thành rẻ, phù hợp với nhiều đối tượng khách hàng, nên chúng ta có thể bắt gặp hình ảnh bảng hiệu bạt hiflex ở khắp các con đường.",
      "Là loại bảng hiệu được làm từ Bạt Hiflex – là chất liệu có màu trắng sữa, có độ co giãn tốt, chịu được thời tiết nắng mưa ở Việt Nam.",
      "Cấu tạo bảng hiệu từ khung sắt phẳng hoặc khung sắt hộp, nhôm ốp viền cùng Bạt Hiflex căng phủ bề mặt.",
    ],
    hinhanh: [
      {
        id: 1,
        link: "/images/banghieu/bat1-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bien-quang-cao-bat-hiflex-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Bạt Hiflex cho sự kiện",
      },
      {
        id: 2,
        link: "/images/banghieu/bat2-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bang-hieu-bat-hiflex-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Bạt Hiflex đứng",
      },
      {
        id: 3,
        link: "/images/banghieu/bat3-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bien-quang-cao-bat-hiflex-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Bạt Hiflex",
      },
      {
        id: 4,
        link: "/images/banghieu/bat4-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thiet-ke-bang-hieu-bat-hiflex-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Bạt Hiflex hộp đèn",
      },
      {
        id: 5,
        link: "/images/banghieu/bat5-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bien-quang-cao-bat-hiflex-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Bạt Hiflex hộp đèn",
      },
      {
        id: 6,
        link: "/images/banghieu/bat6-lam-bien-quang-cao-bmt-daklak.webp",
        alt: "bien-quang-cao-bat-hiflex-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Bạt Hiflex hộp đèn",
      },
    ],
  },
  {
    id: 5,
    tieude: "Bảng hiệu Neon",
    timkiem: "lam-bien-quang-cao-neon-tai-bmt-daklak",
    title_seo:
      "Làm biển quảng cáo Neon tại BMT, Đắk Lắk | Giá rẻ, đẹp, chất lượng 100%",
    des_seo:
      "Thiết kế & thi công biển quảng cáo tại BMT, Đắk Lắk và toàn quốc với giá rẻ nhất. Hơn 20 năm kinh nghiệm, phục vụ 1000+ khách hàng. Liên hệ: 096.4040.895",
    mota_sp:
      "Ánh sáng neon phong cách retro, thu hút ánh nhìn và tạo ấn tượng mạnh..",
    hinhanh_sp: "/images/banghieu/neon1-lam-bien-quang-cao-bmt-daklak.jpg",
    mota: [
      "Bảng hiệu Neon được làm từ ống thủy tinh chứa khí neon hoặc argon, khi được kích hoạt bằng điện sẽ phát sáng với nhiều màu sắc khác nhau. Ống neon có thể uốn theo hình dạng chữ viết hoặc hình ảnh theo thiết kế.",
      "Bảng hiệu Neon là một cộng cụ trang trí cực kỳ nổi bật. Sản phẩm cực kỳ nổi bật phù hợp với nhiều cơ sở kinh doanh. Ngoài ra với giá trị cao của tính thẩm mỹ bảng neon còn được trang trí phòng ngủ, phòng khách,… ",
      "Bảng neon thường xuất hiện nhiều nơi tại như quán bar, quán cà phê,… Làm bảng Neon dùng cho các buổi sự kiện, sinh nhật, hòa nhạc,…",
    ],
    hinhanh: [
      {
        id: 1,
        link: "/images/banghieu/neon1-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bien-quang-cao-neon-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Neon trang trí quảng cáo",
      },
      {
        id: 2,
        link: "/images/banghieu/neon2-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bang-hieu-neon-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Neon",
      },
      {
        id: 3,
        link: "/images/banghieu/neon3-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bien-quang-cao-neon-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Neon",
      },
      {
        id: 4,
        link: "/images/banghieu/neon4-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thiet-ke-bang-hieu-neon-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Neon",
      },
      {
        id: 5,
        link: "/images/banghieu/neon5-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bien-quang-cao-neon-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Neon",
      },
      {
        id: 6,
        link: "/images/banghieu/neon6-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bang-hieu-neon-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Neon",
      },
    ],
  },
  {
    id: 6,
    tieude: "Bảng hiệu Inox",
    timkiem: "lam-bien-quang-cao-inox-tai-bmt-daklak",
    title_seo:
      "Làm biển quảng cáo Inox tại BMT, Đắk Lắk | Giá rẻ, đẹp, chất lượng 100%",
    des_seo:
      "Thiết kế & thi công biển quảng cáo tại BMT, Đắk Lắk và toàn quốc với giá rẻ nhất. Hơn 20 năm kinh nghiệm, phục vụ 1000+ khách hàng. Liên hệ: 096.4040.895",
    mota_sp:
      "Inox nổi bật với độ bền rất cao, không bị oxi hóa và vẻ ngoài sáng bóng.. ",
    hinhanh_sp: "/images/banghieu/inox4-lam-bien-quang-cao-bmt-daklak.jpg",
    mota: [
      "Bảng hiệu inox được làm từ thép không gỉ (inox), có bề mặt sáng bóng và sang trọng. Chúng có thể được cắt, khắc hoặc dập nổi để tạo ra các chữ hoặc hình ảnh theo thiết kế yêu cầu.",
      "Bảng hiệu inox có độ bền rất cao, chống ăn mòn và chịu được tác động của thời tiết khắc nghiệt. Chúng không bị rỉ sét, dễ dàng bảo trì và giữ được vẻ ngoài sáng đẹp trong thời gian dài.",
      "Bề mặt inox tạo cảm giác sang trọng và chuyên nghiệp, phù hợp với làm biển quảng cáo kinh doanh.",
    ],
    hinhanh: [
      {
        id: 1,
        link: "/images/banghieu/inox1-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bien-quang-cao-inox-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Inox",
      },
      {
        id: 2,
        link: "/images/banghieu/inox2-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bang-hieu-inox-gia-re-tai-bmt-daklak",
        mota: "Biển chữ Inox đèn led",
      },
      {
        id: 3,
        link: "/images/banghieu/inox3-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bien-quang-cao-inox-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Inox",
      },
      {
        id: 4,
        link: "/images/banghieu/inox4-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bien-quang-cao-inox-tot-nhat-bmt-daklak",
        mota: "Biển chữ nổi Inox",
      },
      {
        id: 5,
        link: "/images/banghieu/inox5-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bien-quang-cao-inox-tot-nhat-bmt-daklak",
        mota: "Biển hiệu chữ inox vàng",
      },
      {
        id: 6,
        link: "/images/banghieu/inox6-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bien-quang-cao-inox-tot-nhat-bmt-daklak",
        mota: "Mẫu chữ Inox",
      },
    ],
  },

  {
    id: 7,
    tieude: "Bảng hiệu Gỗ",
    timkiem: "lam-bien-quang-cao-go-tai-bmt-daklak",
    title_seo:
      "Làm biển quảng cáo Gỗ tại BMT, Đắk Lắk | Giá rẻ, đẹp, chất lượng 100%",
    des_seo:
      "Thiết kế & thi công biển quảng cáo tại BMT, Đắk Lắk và toàn quốc với giá rẻ nhất. Hơn 20 năm kinh nghiệm, phục vụ 1000+ khách hàng. Liên hệ: 096.4040.895",
    mota_sp:
      "Gỗ mang vẻ đẹp tự nhiên, sang trọng thường được dùng những nơi mộc mạc truyền thống..",
    hinhanh_sp: "/images/banghieu/go4-lam-bien-quang-cao-bmt-daklak.jpg",
    mota: [
      "Bảng hiệu gỗ được làm từ các loại gỗ tự nhiên hoặc công nghiệp, có thể được chạm khắc, sơn hoặc phủ lớp bảo vệ. Bề mặt gỗ mang lại vẻ đẹp tự nhiên và ấm áp, có thể tùy chỉnh theo thiết kế và kích thước yêu cầu. ",
      "Bảng hiệu gỗ tạo cảm giác sang trọng và gần gũi, phù hợp cho các doanh nghiệp muốn thể hiện phong cách cổ điển hoặc truyền thống.",
      "Chúng dễ dàng gia công, có thể chạm khắc chi tiết và sơn theo màu sắc mong muốn. Gỗ có khả năng cách nhiệt tốt và bền nếu được bảo trì đúng cách.",
    ],
    hinhanh: [
      {
        id: 1,
        link: "/images/banghieu/go1-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bien-quang-cao-go-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Gỗ",
      },
      {
        id: 2,
        link: "/images/banghieu/go2-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bang-hieu-go-gia-re-tai-bmt-daklak",
        mota: "Mẫu bảng hiệu Gỗ",
      },
      {
        id: 3,
        link: "/images/banghieu/go3-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thi-cong-bien-quang-cao-go-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Gỗ",
      },
      {
        id: 4,
        link: "/images/banghieu/go4-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "thiet-ke-bang-hieu-go-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Gỗ",
      },
      {
        id: 5,
        link: "/images/banghieu/go5-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bien-quang-cao-go-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Gỗ",
      },
      {
        id: 6,
        link: "/images/banghieu/go6-lam-bien-quang-cao-bmt-daklak.jpg",
        alt: "lam-bang-hieu-go-tot-nhat-bmt-daklak",
        mota: "Mẫu bảng hiệu Gỗ",
      },
    ],
  },
];

export const data_qc_other = [
  {
    id: 1,
    tieude: "In, Ấn",
    timkiem: "in-an-quang-cao-gia-re-tai-bmt-dak-lak",
    title_seo: "Dịch Vụ In Ấn Giá Rẻ tại BMT, Đắk Lắk | Top Chất Lượng 100%",
    des_seo:
      "Dịch vụ in & ấn menu, thiệp mời, tờ rơi, tem nhãn.. tại BMT, Đắk Lắk. Giá rẻ, chất lượng cao 20 năm kinh nghiệm, phục vụ 1000+ khách hàng. Liên hệ: 096.4040.895",
    mota_sp: "In menu, tờ rơi, thiệp mời, danh thiếp, tem nhãn..",
    hinhanh_sp: "",
    mota: [
      "Nhận in, ấn mọi loại như: in menu, poster, tờ rơi, thiệp mời, danh thiếp, catalog, voucher, in tem nhãn, phiếu bảo hành, in bạt hiflex, decal,… tại BMT Đắk Lắk.",
      "Với năng lực sản xuất quy mô công nghiệp, máy móc công suất lớn cùng công nghệ in Offset nhiều màu, in Laser không cần mực và in Flexo tốc độ cao,.. đáp ứng tốt nhất mọi yêu cầu của khách hàng.",
      "Quảng cáo BMT đã trở thành đơn vị in Giá Rẻ mà chất lượng vẫn được đảm bảo tại TP.BMT, Đắk Lắk",
    ],
    hinhanh: [
      {
        id: 1,
        link: "/images/in1-in-an-gia-re-bmt-daklak.png",
        alt: "dich-vu-in-an-gia-re-tai-bmt-daklak",
        mota: "Dịch vụ In, Ấn giá rẻ BMT",
      },
      {
        id: 2,
        link: "/images/in2-in-an-gia-re-bmt-daklak.gif",
        alt: "dich-vu-in-gia-re-tai-bmt-daklak",
        mota: "In, ấn quảng cáo các loại BMT",
      },
      {
        id: 3,
        link: "/images/in3-in-an-gia-re-bmt-daklak.jpg",
        alt: "dich-vu-in-an-gia-re-tai-bmt-daklak",
        mota: "Máy móc in, ấn hiện đại ",
      },
      {
        id: 4,
        link: "/images/in4-in-an-gia-re-bmt-daklak.jpg",
        alt: "dich-vu-in-an-gia-re-tai-bmt-daklak",
        mota: "Máy móc in, ấn hiện đại ",
      },
    ],
  },
  {
    id: 2,
    tieude: "Backdrop sự kiện",
    timkiem: "lam-su-kien-backdrop-trien-lam-quang-cao-gia-re-tai-bmt-dak-lak",
    title_seo:
      "Làm Backdrop Sự Kiện & Triển Lãm tại BMT, Đắk Lắk | Đẹp, Giá Rẻ 100%",
    des_seo:
      "Thi công & thiết kế backdrop sự kiện, triển lãm, trưng bày quảng cáo tại BMT, Đắk Lắk. Đẹp, giá rẻ, chất lượng 100%. Gọi ngay để nhận báo giá: 096.4040.895",
    mota_sp: "Thi công backdrop sự kiện, triển lãm, trưng bày..",
    hinhanh_sp: "",
    mota: [
      "Chuyên thiết kế, thi công Backdrop trọn gói cho các buổi sự kiện, sinh nhật, cưới hỏi, khai trương cửa hàng, gian hàng triển lãm… với mức giá rẻ, dịch vụ chuyên nghiệp, đa dạng chất liệu kiêu mẫu tự tin sẽ mang cho khách hàng những hậu kỳ cực kỳ chuyên nghiệp và ấn tượng.",
      "Hơn 20+ năm kinh nghiệm làm việc trong lĩnh vực quảng cáo, thiết kế thi công backdrop chuyên nghiệp giá rẻ tại BMT, Đắk Lắk.",
      "Hằng năm Quảng Cáo BMT đã tổ chức cho hàng trăm sự kiện lớn, nhỏ không chỉ tại khu vực Đắk Lắk mà còn trên toàn quốc. Hỗ trợ khách hàng khảo sát, thiết kế, thi công lắp đặt backdrop theo yêu cầu, sân khấu, đèn chiếu sáng, khu vực check in…",
      "Quảng Cáo BMT được xem là một trong những đơn vị hàng đầu chuyên thiết kế thi công gian hàng triển lãm, trừng bày hội chợ uy tín, chuyên nghiệp, giá rẻ tại BMT, Đắk Lắk.",
      "Chúng tôi cam kết sẽ mang đến cho quý khách hàng những trải nghiệm tuyệt vời với chất lượng và mức giá hợp lí nhất trên từng mẫu thiết kế.",
    ],
    hinhanh: [
      {
        id: 1,
        link: "/images/backdrop1-su-kien-quang-cao-bmt.jpg",
        alt: "thi-cong-backdrop-su-kien-trien-lam-quang-cao-bmt-daklak",
        mota: "Thiết kế, thi công backdrop trọn gói chuyên nghiệp BMT",
      },
      {
        id: 2,
        link: "/images/backdrop2-su-kien-quang-cao-bmt.jpg",
        alt: "thi-cong-backdrop-su-kien-trien-lam-quang-cao-bmt-daklak",
        mota: "Phông nền ngày 20/10 thiết kế đẹp, hiện đại",
      },
      {
        id: 3,
        link: "/images/backdrop3-su-kien-quang-cao-bmt.jpg",
        alt: "thi-cong-backdrop-su-kien-trien-lam-quang-cao-bmt-daklak",
        mota: "Mẫu backdrop in bạt hiflex",
      },
      {
        id: 4,
        link: "/images/backdrop4-su-kien-quang-cao-bmt.jpg",
        alt: "thi-cong-backdrop-su-kien-trien-lam-quang-cao-bmt-daklak",
        mota: "Thiết kế gian hàng triển lãm, hội chợ",
      },
      {
        id: 5,
        link: "/images/backdrop5-su-kien-quang-cao-bmt.jpg",
        alt: "thi-cong-backdrop-su-kien-trung-bay-quang-cao-bmt-daklak",
        mota: "Thiết kế, thi công gian hàng triển lãm",
      },
      {
        id: 6,
        link: "/images/backdrop6-su-kien-quang-cao-bmt.jpg",
        alt: "thi-cong-backdrop-su-kien-trien-lam-quang-cao-bmt-daklak",
        mota: "Thi công gian hàng sự kiện nhanh chóng chuyên nghiệp",
      },
      {
        id: 7,
        link: "/images/backdrop7-su-kien-quang-cao-bmt.jpg",
        alt: "thi-cong-backdrop-su-kien-trien-lam-quang-cao-bmt-daklak",
        mota: "thi công phông nền/backdrop sân khấu hội nghị",
      },
      {
        id: 8,
        link: "/images/backdrop8-su-kien-quang-cao-bmt.jpg",
        alt: "thi-cong-backdrop-su-kien-trien-lam-quang-cao-bmt-daklak",
        mota: "Thi công backdrop đẹp",
      },
      {
        id: 9,
        link: "/images/backdrop9-su-kien-quang-cao-bmt.jpg",
        alt: "thi-cong-backdrop-su-kien-trien-lam-quang-cao-bmt-daklak",
        mota: "Mẫu thiết kế gian hàng hội chợ",
      },
      {
        id: 10,
        link: "/images/backdrop10-su-kien-quang-cao-bmt.jpg",
        alt: "thi-cong-backdrop-su-kien-trien-lam-quang-cao-bmt-daklak",
        mota: "Mẫu thiết kế gian hàng hội chợ, triển lãm",
      },
    ],
  },
  {
    id: 3,
    tieude: "Cắt CNC, Laser",
    timkiem: "cat-cnc-laser-gia-re-tai-bmt-dak-lak",
    title_seo: "Cắt CNC & Laser Giá Rẻ tại BMT, Đắk Lắk | Chất Lượng Top 100%",
    des_seo:
      "Dịch vụ cắt CNC & laser tại BMT, Đắk Lắk. Chất lượng cao, giá rẻ, độ chính xác cao. Hơn 20 năm kinh nghiệm, phục vụ 1000+ khách hàng. Liên hệ ngay: 096.4040.895",
    mota_sp: "Cắt CNC, Laser sắt thép, gỗ, mica, decal..",
    hinhanh_sp: "",
    mota: [
      "Dịch vụ cắt CNC kim loại và cắt CNC sắt tại BMT, Đắk Lắk theo bản vẽ bằng máy cắt CNC Plasma, Laser… có thể cắt mọi hình dạng và kích thước theo đúng như bản vẽ đưa ra.",
      "Quảng Cáo BMT nhận cắt CNC mọi đơn hàng mà không phân biệt số lượng ít hay nhiều, không phân biệt đối tượng cá nhân hay công ty.",
      "Tốc độ cắt rất nhanh và cắt trên nhiều loại vật liệu như: sắt, kim loại, nhôm, inox, đồng, thép, gỗ, decal mica,.. Công nghệ cắt CNC kim loại, cắt CNC sắt BMT hiện đại giúp tập trung nguồn nhiệt chính xác vào điểm cắt, nên không làm biến dạng vật liệu cần cắt, vết cắt hiếm khi bị cứng.",
      "Máy móc hiện đại đời mới nhất như máy cắt Laser CNC, máy cắt Plasma CNC SPC-1530, SPC-2060. Máy cắt ống plasma CNC SPT-0260 và máy cắt Laser SLC-2060 giúp đáp mọi yêu cầu của khách hàng.",
    ],
    hinhanh: [
      {
        id: 1,
        link: "/images/cnc1-cat-cnc-quang-cao-bmt.jpg",
        alt: "cat-cnc-gia-re-tai-bmt-daklak",
        mota: "CNC kim loại",
      },
      {
        id: 2,
        link: "/images/cnc2-cat-cnc-quang-cao-bmt.jpg",
        alt: "dich-vu-cat-cnc-laser-gia-re-tai-bmt-daklak",
        mota: "Mẫu cắt CNC Laser",
      },
      {
        id: 3,
        link: "/images/cnc3-cat-cnc-quang-cao-bmt.jpg",
        alt: "gia-cong-cat-cnc-gia-re-tai-bmt-daklak",
        mota: "Cắt CNC tạo hình đẹp",
      },
      {
        id: 4,
        link: "/images/cnc4-cat-cnc-quang-cao-bmt.jpg",
        alt: "gia-cong-cat-cnc-chat-luong-tai-bmt-daklak",
        mota: "Cắt CNC kim loại hoa văn",
      },
      {
        id: 5,
        link: "/images/cnc5-cat-cnc-quang-cao-bmt.jpg",
        alt: "cat-cnc-khac-laser-gia-re-tai-bmt-daklak",
        mota: "Cắt CNC gỗ",
      },
    ],
  },
];

export default data_bh_detail;
