import React, { useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import data_bh_detail from "../../data";

const HeaderMobile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  const [isOpenSubmenu, setIsOpenSubmenu] = useState(true);
  const toggleSubMenu = () => setIsOpenSubmenu(!isOpenSubmenu);

  return (
    <div className="header-mobile">
      <div className="logo-box">
        <Link className="img-box" to="/">
          <img
            className="img-logo"
            src={
              process.env.PUBLIC_URL +
              "/images/logo-lam-bang-hieu-gia-re-tai-bmt-daklak.png"
            }
            alt="lam-bien-quang-cao-tai-bmt-daklak"
          />

          <h1>Biển Quảng Cáo BMT</h1>
        </Link>

        <span
          className={`icon-menu ${isOpen ? "hide" : ""}`}
          onClick={toggleMenu}
        >
          <FontAwesomeIcon className="icon_pen" icon={faBars} />
        </span>
      </div>

      <div
        className={`modal-overlay ${isOpen ? "visible" : ""}`}
        onClick={toggleMenu}
      ></div>
      <div className="sidebar-container">
        <div
          className={`close-icon ${isOpen ? "show" : ""}`}
          onClick={toggleMenu}
        >
          &times;
        </div>
        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <ul>
            <li onClick={toggleMenu}>
              <Link to="/">Trang chủ</Link>
            </li>
            <li className={`li-lambanghieu ${isOpenSubmenu ? "visible" : ""}`}>
              <div className="muiten-box">
                <a href="#services">Làm bảng hiệu</a>
                <span
                  onClick={toggleSubMenu}
                  className={`muiten ${isOpenSubmenu ? "visible" : "disable"}`}
                >
                  <FontAwesomeIcon className="icon_pen" icon={faChevronDown} />
                </span>
              </div>
              <ul
                className={`submenu-lambanghieu ${
                  isOpenSubmenu ? "visible" : ""
                }`}
              >
                {data_bh_detail.map((item, index) => {
                  return (
                    <li onClick={toggleMenu} key={item.id}>
                      <Link to={`/${item.timkiem}`}>{item.tieude}</Link>
                    </li>
                  );
                })}
              </ul>
            </li>

            <li>
              <a href="#contact">Liên hệ báo giá miễn phí :</a>
            </li>
          </ul>
          <div className="sdt">
            <div>096.4040.895</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderMobile;
