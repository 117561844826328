import React from "react";
import "./style.scss";
import Header from "../common/header/Header";
import { useMediaQuery } from "react-responsive";
import Content from "./content/Content";
import Reason from "./reason/Reason";
import Procedure from "./procedure/Procedure";
import Youtub from "./youtub/Youtub";
import Footer from "../common/footer/Footer";
import Slice from "./slice/Slice";
import ContactMobile from "./contact/contact-mobile/ContactMobile";
import ContactDestop from "./contact/contact-destop/ContactDestop";
import About from "./about/About";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Page = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Làm Biển Quảng Cáo giá rẻ tại BMT, Đắk Lắk | Tăng Hiệu Quả 100%
        </title>
        <meta
          name="description"
          content="Thiết kế & thi công biển quảng cáo tại BMT, Đắk Lắk và toàn quốc. Hơn 10 năm kinh nghiệm, phục vụ 1000+ khách hàng. Liên hệ báo giá miễn phí: 096.4040.895"
        />
        <link rel="canonical" href="https://www.bienquangcaobmt.com/" />
      </Helmet>
      <div className="page">
        {isDesktop && (
          <div className="destop">
            <Header />
            <Slice />
            <Content />
            <About />
            <Reason />
            <Procedure />
            <Youtub />
            <Footer />
            <ContactDestop />
          </div>
        )}

        {isMobile && (
          <div className="">
            <Header />
            <Slice />

            <Content />
            <About />
            <Reason />
            <Procedure />
            <Youtub />
            <Footer />
            <ContactMobile />
          </div>
        )}
      </div>
    </HelmetProvider>
  );
};

export default Page;
