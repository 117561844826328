import React from "react";
import "./style.scss";

const Slice = () => {
  return (
    <div className="slice">
      <div className="img_slide_box">
        <img
          className="img_slide"
          src={
            process.env.PUBLIC_URL +
            "/images/slide-lam-bien-quang-cao-gia-re-tai-bmt-daklak.png"
          }
          alt="lam-bien-quang-cao-tai-bmt-daklak"
        />
      </div>
    </div>
  );
};

export default Slice;
