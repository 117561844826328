import React, { useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import data_bh_detail from "../../data";

const HeaderDestop = () => {
  const [isOpenSubmenu, setIsOpenSubmenu] = useState(false);
  const toggleSubMenu = () => setIsOpenSubmenu(!isOpenSubmenu);

  return (
    <div className="header-destop">
      <div className="logo-box">
        <div className="img-box">
          <img
            className="img-logo"
            src={
              process.env.PUBLIC_URL +
              "/images/logo-lam-bang-hieu-gia-re-tai-bmt-daklak.png"
            }
            alt="lam-bien-quang-cao-tai-bmt-daklak"
          />
          <h1>Biển Quảng Cáo BMT</h1>
        </div>
      </div>
      <div className="menu-destop">
        <div>
          <Link to="/" className="menu-trangchu">
            Trang chủ
          </Link>
        </div>
        <div className="li-lambanghieu">
          <span onClick={toggleSubMenu} className="menu-lambh">
            Làm bảng hiệu
          </span>
          <span
            onClick={toggleSubMenu}
            className={`muiten ${isOpenSubmenu ? "visible" : "disable"}`}
          >
            <FontAwesomeIcon className="icon_pen" icon={faChevronDown} />
          </span>
          <ul
            className={`submenu-lambanghieu ${isOpenSubmenu ? "visible" : ""}`}
          >
            {data_bh_detail.map((item, index) => {
              return (
                <li onClick={toggleSubMenu} key={item.id}>
                  <Link to={`/${item.timkiem}`}>{item.tieude}</Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="menu-lienhe">
          <a href="https://zalo.me/0964040895">Liên hệ</a>
        </div>
      </div>
    </div>
  );
};

export default HeaderDestop;
