import React from "react";
import "./style.scss";
import { useMediaQuery } from "react-responsive";

const Procedure = () => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const data_procedure = [
    {
      id: 1,
      name: "Tư Vấn",
      des: "Tiếp nhận thông tin yêu cầu của khách hàng liên hệ tư vấn và ước lượng quy mô sản phẩm",
      img: "/images/qt_tuvan-bang-hieu-bmt.png",
      color: "#2A33E2",
    },
    {
      id: 2,
      name: "Thiết Kế",
      des: "Phác hoạ và tính toán chi tiết sản phẩm cho từng dự án sắp thực hiện",
      img: "/images/qt_thietke-bang-hieu-bmt.png",
      alt: "thiet-ke-bang-hieu-bmt",
      color: "#9AD0AD",
    },
    {
      id: 3,
      name: "Thi Công Lắp Đặt",
      des: "Thi công tại xưởng, lắp đặt nhanh chóng đúng kỳ hạn.",
      img: "/images/qt_thicong-bang-hieu-bmt.png",
      alt: "thi-cong-bang-hieu-bmt",
      color: "#FBC933",
    },
    {
      id: 4,
      name: "Thanh Toán Bảo Trì",
      des: "Nghiệm thu nhận thanh toán, bảo hành bảo trì tiêu chuẩn của NSX",
      img: "/images/qt_thanhtoan-bang-hieu-bmt.png",
      alt: "thanh-toan-bang-hieu-bmt",
      color: "#F97168",
    },
  ];

  return (
    <div className="procedure">
      <div className="procedure-title">
        <h1>Quy trình thực hiện</h1>
        <p>
          Với hơn 10+ năm kinh nghiệm trong ngành thiết kế & thi công Biển Quảng
          Cáo tại Đắk Lắk, BMT. Chúng tôi luôn có một quy trình làm việc nhanh
          chóng và chuyên nghiệp.
        </p>
      </div>
      {isMobile &&
        data_procedure.map((item, index) => {
          return (
            <div className="procedure-mobile" key={item.id}>
              <div className="procedure-img">
                <div
                  className="procedure-img-box"
                  style={{ border: `3px solid ${item.color}` }}
                >
                  <img src={process.env.PUBLIC_URL + item.img} alt="" />
                </div>
              </div>
              <div className="procedure-con"></div>
              <div className="procedure-des">
                <p
                  style={{ background: item.color }}
                >{`${item.id}. ${item.name}`}</p>
                <div>{item.des}</div>
              </div>
            </div>
          );
        })}
      {isDesktop &&
        data_procedure.map((item, index) => {
          return (
            <div className="procedure-destop" key={item.id}>
              {item.id % 2 !== 0 ? (
                <div
                  className="procedure-img"
                  style={{ borderRight: "3px dashed #afabab" }}
                >
                  <div
                    className="procedure-img-box"
                    style={{
                      border: `3px solid ${item.color}`,
                      right: 0,
                      transform: "translateX(50%)",
                    }}
                  >
                    <img src={process.env.PUBLIC_URL + item.img} alt="" />
                  </div>
                  <div className="procedure-con"></div>
                </div>
              ) : (
                <div
                  className="procedure-des"
                  style={{ justifyContent: "left" }}
                >
                  <div className="procedure-des-box">
                    <p
                      style={{ background: item.color }}
                    >{`${item.id}. ${item.name}`}</p>
                    <div>{item.des}</div>
                  </div>
                </div>
              )}

              {item.id % 2 !== 0 ? (
                <div
                  className="procedure-des"
                  style={{ justifyContent: "right" }}
                >
                  <div className="procedure-des-box">
                    <p
                      style={{ background: item.color }}
                    >{`${item.id}. ${item.name}`}</p>
                    <div>{item.des}</div>
                  </div>
                </div>
              ) : (
                <div
                  className="procedure-img"
                  style={{ borderLeft: "3px dashed #afabab" }}
                >
                  <div
                    className="procedure-img-box"
                    style={{
                      border: `3px solid ${item.color}`,
                      left: 0,
                      transform: "translateX(-50%)",
                    }}
                  >
                    <img src={process.env.PUBLIC_URL + item.img} alt="" />
                  </div>
                  <div className="procedure-con"></div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default Procedure;
