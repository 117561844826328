import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import data_bh_detail, { data_qc_other } from "../../common/data";

const Content = () => {
  return (
    <div className="content">
      {data_bh_detail.map((item, index) => {
        return (
          <div className="bh_box" key={item.id}>
            <div className="bh_des_box">
              <div className="bh_name">{item.tieude}</div>
              <div className="bh_des">{item.mota_sp}</div>

              <Link to={`/${item.timkiem}`} className="bh_button">
                Xem thêm
              </Link>
            </div>
            <div className="bh_img">
              <img
                src={process.env.PUBLIC_URL + item.hinhanh_sp}
                alt="bien quang cao bmt"
              />
            </div>
          </div>
        );
      })}
      <div className="qc_other">
        <div className="box_h1">
          <h1>Dịch vụ Quảng cáo khác</h1>
        </div>
        <div className="qc_other-box">
          {data_qc_other.map((item, index) => {
            return (
              <div className="qc_other-main" key={item.id}>
                <Link to={`/${item.timkiem}`} className="qc_other-button">
                  {item.tieude}
                </Link>
                <div className="qc_other-hide"></div>
                <div className="qc_other-des">{item.mota_sp}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Content;
